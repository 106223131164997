import * as Yup from "yup";

export const initialValues = {
  name: "",
  parthner_store: "",
  parthner_f5: "",
};

export const validation = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  parthner_store: Yup.string().required("Campo obrigatório"),
  parthner_f5: Yup.string().required("Campo obrigatório"),
});
