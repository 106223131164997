import { ErrorMessage, Form, Formik, Field } from "formik";
import { useAcaoContext } from "../../contexts/acaoContext";
import { validationSms, valuesSms } from "./config";
import Button from "../../components/button";
import imgSms from "../../assets/img/sms-login.svg";
import { useState } from "react";

export function FormSms(props) {
  const { onChange } = props;
  const { mobile, timer } = useAcaoContext();
  const [loading, setLoading] = useState(false);
  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={valuesSms}
      validationSchema={validationSms}
      onSubmit={async (values, actions) => {
        setLoading(true);
        await onChange();
        setLoading(false);
      }}
    >
      {(props) => {
        return (
          <Form>
            <img src={imgSms} alt="" />

            <div className="container-input">
              <h3>Receber por SMS</h3>
              <Field name="sms" type="tel" value={mobile} disabled={true} />
              <ErrorMessage name="text" data-testid="error" component="span" />
            </div>

            <Button
              title="Enviar"
              type="submit"
              loading={loading}
              disabled={timer}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
