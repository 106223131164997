import { useState } from "react";
import { ErrorMessage, Form, Formik, Field } from "formik";
import { valuesEmail, validationEmail } from "./config";
import { useAcaoContext } from "../../contexts/acaoContext";
import Button from "../button";
import imgEmail from "../../assets/img/email.svg";

export function FormEmail(props) {
  const { onChange } = props;
  const { timer, userEmail } = useAcaoContext();
  const [loading, setLoading] = useState(false);
  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={valuesEmail}
      validationSchema={validationEmail}
      onSubmit={async (values, actions) => {
        setLoading(true);
        await onChange(values.email);
        setLoading(false);
      }}
    >
      {(props) => {
        if (userEmail !== null && userEmail !== "") {
          var emailFormatado = userEmail.split("@")[1];
        }
        return (
          <Form>
            <img src={imgEmail} alt="" />

            <div className="container-input">
              <h3>Receber por E-mail</h3>
              <Field
                name="email"
                placeholder="E-mail"
                value={
                  userEmail !== null && userEmail !== ""
                    ? userEmail.substr(0, 3) + "XXXX@" + emailFormatado
                    : null
                }
                disabled={userEmail !== null && userEmail !== "" ? true : false}
              />
              <ErrorMessage name="email" component="span" />
            </div>

            <Button
              title="Enviar"
              type="submit"
              loading={loading}
              disabled={timer}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
