import { useLocation } from "react-router-dom";
import { Container } from "./styles";
import imgAcessoNegado from "../../assets/img/acessoNegado.svg";
// import imgUniclass from "../../assets/img/uniclass.svg";
import LogoItauComPersonnalite from "../../assets/img/itau.svg";
import LogoItau from "../../assets/img/LogoItau.svg";
import Footer from "../../components/footer";
export function AcessoNegadoItau() {
  const width = window.screen.width;

  const location = useLocation();
  const url = location.state.key;
  let color, img;

  if (url === "personnalite" || url === "personnalite3") {
    color = "#000D3C";
    img = LogoItauComPersonnalite;
  } else {
    color = "#0131FF";
    img = LogoItau;
  }

  return (
    <>
      <Container color={color}>
        <div className="content">
          <div className="container-img">
            <img src={imgAcessoNegado} alt="acesso negado" />
          </div>
          <div className="container-text">
            <h1>Olá!</h1>
            <p>
              O acesso ao seu benefício<br></br> do Minhas Vantagens é<br></br>{" "}
              exclusivo por meio do<br></br> aplicativo do Itaú.
            </p>
          </div>
        </div>

        <div className="logo">
          <img src={img} alt="logo" />
        </div>
      </Container>
      {width >= 480 ? <Footer /> : null}
    </>
  );
}
