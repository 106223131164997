import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import Footer from "../../../components/footer";
import ModalFaleConosco from "../../../components/modal/faleConosco";

import { useAcaoContext } from "../../../contexts/acaoContext";

import { Container } from "./styles";

import img from "../../../assets/img/logo-home.svg";
import img1 from "../../../assets/img/ajuda.png";
import img2 from "../../../assets/img/fale.svg";
import img3 from "../../../assets/img/voltar.svg";

export function PrecisaAjuda() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  let user = cookies.get("yp_user");
  if (!user) {
    user = JSON.parse(localStorage.getItem("yp_user"));
  }
  const { configuracao } = useAcaoContext();
  const [openFaleConosco, setOpenFaleConosco] = useState();

  function handleOpenAba(event) {
    const e = event.target.parentNode;
    const itens = document.getElementsByClassName("item");
    Array.from(itens).forEach((el) => {
      el.classList.remove("active");
    });
    e.classList.toggle("active");
  }

  return (
    <Container
      backgroundColor={configuracao?.background}
      button_color={configuracao?.button_color}
    >
      <div className="container">
        <div className="topo">
          <div className="logos">
            <img src={img1} alt="" />
            <img src={img} alt="" />
          </div>
          <div className="voltar" onClick={() => navigate(-1)}>
            <img src={img3} alt="" />
            <span>Voltar</span>
          </div>
        </div>

        <div className="conteudo">
          <div className="item" onClick={handleOpenAba}>
            <p>O que é YETZPROMO?</p>
            <span>
              Uma plataforma de premiações com vouchers digitais para você
              resgatar, a partir de regras e prazos definidos pela empresa
              contratante.
            </span>
          </div>
          <div className="item" onClick={handleOpenAba}>
            <p>Qual é meu Login?</p>
            <span>
              Isso depende de quem está lhe premiando. Seu login pode ser o seu
              CPF, e-mail, telefone, funcional ou Id definido. Se tiver dúvida
              pergunte ao responsável da ação qual o tipo de informação é
              utilizado para efetuar seu login.
            </span>
          </div>
          <div className="item" onClick={handleOpenAba}>
            <p>Quais configurações ideais para utilização da Plataforma?</p>
            <span>
              A Plataforma é melhor visualizada em navegadores com suporte às
              especificações HTML5 e CSS3 (Google Chrome, Mozilla Firefox,
              Microsoft Edge, Safari entre outros). Recomendamos que você
              utilize a versão mais atual destes navegadores, pois alguns
              recursos podem não ser exibidos corretamente caso não esteja
              atualizado.
            </span>
          </div>
          <div className="item" onClick={handleOpenAba}>
            <p>
              Ao realizar meu cadastro não recebi o código de verificação o que
              faço?
            </p>

            <span>
              Para liberar possíveis bloqueios no recebimento do código de
              verificação via WhatsApp, siga o tutorial abaixo para Android e
              iOS.
            </span>

            <span>
              <strong>Desbloqueio número Yetz no WhatsApp Android</strong>
            </span>

            <ol>
              <li>
                Abra o WhatsApp e toque em <strong>Menu</strong> (ícone com 3
                bolinhas)
              </li>
              <li>
                Depois toque em <strong>Configurações.</strong>
              </li>
              <li>
                Selecione a opção <strong>Privacidade</strong> e toque em{" "}
                <strong>Bloqueados.</strong>
              </li>
              <li>
                Localize e selecione o número da YETZ e toque em{" "}
                <strong>Desbloquear.</strong>
              </li>
            </ol>

            <span>
              <strong>Desbloqueio número Yetz no WhatsApp IOS</strong>
            </span>

            <ol>
              <li>
                Abra o WhatsApp e toque em <strong>Configurações.</strong>
              </li>
              <li>
                Selecione a opção Privacidade e toque em{" "}
                <strong>Bloqueados.</strong>
              </li>
              <li>
                Localize e selecione o número da YETZ e toque em{" "}
                <strong>Desbloquear Empresa.</strong>
              </li>
            </ol>

            <span>
              Caso não funcione o desbloqueio, faça uma nova tentativa
              utilizando um número de celular diferente do informado
              anteriormente.
            </span>
          </div>
          <div className="item" onClick={handleOpenAba}>
            <p>Meu número de celular cadastrado está errado, o que fazer?</p>
            <span>
              Entre em contato com a Equipe Yetz através do FALE CONOSCO. Clique
              em “ainda precisa de ajuda? CLIQUE AQUI” e envie sua solicitação.
            </span>
          </div>
          <div className="item" onClick={handleOpenAba}>
            <p>Como altero o número do Celular cadastrado na Plataforma?</p>
            <span>
              Entre em contato com a Equipe Yetz através do FALE CONOSCO. Clique
              em “ainda precisa de ajuda? CLIQUE AQUI” e solicite a alteração do
              celular cadastrado.
            </span>
          </div>
          <div className="item" onClick={handleOpenAba}>
            <p>A ação foi encerrada, o que fazer?</p>
            <span>
              Após encerrada, não é possível o acesso à Plataforma YETZPROMO
              e/ou a disponibilização do voucher.
            </span>
          </div>
          <div className="item" onClick={handleOpenAba}>
            <p>Ocorreu “usuário não encontrado”, o que fazer?</p>
            <span>
              Verifique se está digitando o Login correto. Se estiver, é
              necessário informar ao responsável da ação para que ele analise e
              entre em contato com a Equipe Yetz.
            </span>
          </div>
          <div className="item" onClick={handleOpenAba}>
            <p>Como entro em contato com a YETZPROMO?</p>
            <span>
              Se mesmo com a leitura dos itens acima você não conseguir acessar
              a Plataforma, clique e envie uma mensagem para “AINDA PRECISA DE
              AJUDA?”.
            </span>
          </div>
        </div>
        <div
          className="faleConosco"
          onClick={() => setOpenFaleConosco(!openFaleConosco)}
        >
          <span>Ainda precisa de ajuda?</span>
          <button>
            <img src={img2} alt="" />
            <span>FALE CONOSCO</span>
          </button>
          <div className="voltar" onClick={() => navigate(-1)}>
            <img src={img3} alt="" />
            <span>Voltar</span>
          </div>
        </div>
      </div>
      <Footer />

      <ModalFaleConosco
        isOpen={openFaleConosco}
        handleClose={() => setOpenFaleConosco(false)}
        id_usuario={user?.user_id}
        acao={configuracao?.name}
        link_acao={configuracao?.key}
        login={user?.user_login}
        cor={configuracao?.background}
        label_login={configuracao?.login_label}
        lgpd_cpf_truncado={configuracao?.login_type_cpf_truncated}
        action_id={configuracao?.id}
      />
    </Container>
  );
}
