/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, Navigate, useLocation } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from "universal-cookie";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { api } from "../../../services/api";

import Footer from "../../../components/footer";
import Button from "../../../components/button";
import { BoxVideo } from "../../../components/boxVideo";
import ModalAviso from "../../../components/modal/aviso";
import MenuLateral from "../../../components/menuLateral";

import { Container, LoadLink } from "./styles";
import { ClickAjuda } from "../../principal/styles";

import img1 from "../../../assets/img/sair.svg";
import img3 from "../../../assets/img/mensagem.svg";
import img4 from "../../../assets/img/calendario.svg";
import img6 from "../../../assets/img/mais.svg";
import imgLoader from "../../../assets/img/loader-yetz-3.svg";

import { useAcaoContext } from "../../../contexts/acaoContext";
import { When } from "../../../components/when";
import { RenderLogo } from "../../../components/logos";
import { toast } from "react-toastify";

export function Finalizacao() {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  let user = cookies.get("yp_user");
  if (!user) {
    user = JSON.parse(localStorage.getItem("yp_user"));
  }
  const dadosLogin = cookies.get("yp_dadosLogin");
  const { configuracao } = useAcaoContext();

  const baseUrl = `/${configuracao?.key}`;
  const origin_benefit_click_id = configuracao?.origin_benefit_click_id;
  const linkUrl = origin_benefit_click_id && origin_benefit_click_id !== ""
    ? `${baseUrl}?origin_benefit_click_id=${origin_benefit_click_id}`
    : baseUrl;

  console.log("configuracao", configuracao);

  const [produtos, setProdutos] = useState([]);
  const [configFinal, setConfigFinal] = useState();

  const [active, setActive] = useState(false);
  const [mensagem, setMensagem] = useState();

  const [activeMenu, setActiveMenu] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [erroLink, setErroLink] = useState(false);
  const ref = useRef();
  // const path = useHref().split("/")[1];

  async function linkResgate(key_one, key_two) {
    if (!ref?.current) {
      toast.error("Erro ao gerar link de resgate");
      return;
    }
    // setActiveIframe(true);
    setLoadingLink(true);

    try {
      const response = await api.post(
        `/client/action/reward/link/${key_one}/${key_two}`
      );
      // setLinkIframe(response[0]);
      setLoadingLink(false);
      window.location.href = response[0];
    } catch (error) {
      setLoadingLink(false);
      if (error && error[0]) {
        setErroLink(error[0]);
      } else {
        setErroLink("Algo deu errado! Espere um pouco e recarregue a página.");
      }
    }
  }

  function handleLogout() {
    localStorage.removeItem("timeCodeSending");
  }

  useEffect(() => {
    async function getLink() {
      const variacao_id = location?.state?.variacao_id;
      setLoading(true);
      try {
        const data = await api.post(`/client/action/reward/my`, {
          user_id: user?.user_id,
        });
        setConfigFinal(data?.page);
        setProdutos(data?.rewards ?? []);
        setLoading(false);
        if (!loading) {
          if (variacao_id) {
            data?.rewards.forEach((p, index) => {
              if (p.variation_id === variacao_id) {
                const swiperContainer = document.querySelector(
                  ".resgates-container .swiper"
                );
                if (swiperContainer) {
                  const swip = swiperContainer.swiper;
                  swip.slideTo(index);
                }
              }
            });
          } else {
            nextSlide();
          }
        }
      } catch (error) {
        if (error && error[0]) {
          setMensagem(error[0]);
        } else {
          setMensagem(
            "Algo deu errado! Espere um pouco e recarregue a página."
          );
        }
        setActive(true);
        setLoading(false);
        console.log(error);
      }
    }

    getLink();
    // eslint-disable-next-line
  }, [user?.user_id]);

  function nextSlide() {
    const swiperContainer = document.querySelector(
      ".resgates-container .swiper"
    );
    if (swiperContainer) {
      const swip = swiperContainer.swiper;
      const { innerWidth: width } = window;

      if (width > 480) {
        swip.slideNext();
      }
    }
  }

  if (!user) {
    return <Navigate to={`/${configuracao?.key}`} replace />;
  }

  return (
    <>
      {loadingLink ? (
        <LoadLink>
          <img className="loader" src={imgLoader} alt="" />
        </LoadLink>
      ) : erroLink ? (
        <LoadLink>
          <div className="erro">
            <h3>Ooops!</h3>
            <p>{erroLink}</p>
          </div>
        </LoadLink>
      ) : (
        <Container backgroundColor={configuracao?.background}>
          <div className="container">
            <div className="topo">
              <div className="logos">
                <RenderLogo {...configuracao} />
              </div>
              <div className="links-topo">
                {configuracao?.show_digital_wallet === 1 ? (
                  <div
                    className="verResgatesCupom"
                    onClick={() =>
                      navigate("/" + configuracao?.key + "/carteira")
                    }
                  >
                    <img src={img4} alt="" />
                    <p>Meus Vouchers</p>
                  </div>
                ) : null}
                <Link
                  to={linkUrl}
                  onClick={handleLogout}
                >
                  <img src={img1} alt="Sair" />
                  <span>Sair</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="overlay-modal"></div>
          <div
            className={`${configuracao?.disable_text_my_rewards ? "remove" : ""
              } conteudo container resgates-container slide-container`}
          >
            <div className="text-meus-resgates">
              <h1>{configFinal?.my_rewards_title}</h1>
              <p>{configFinal?.my_rewards_subtitle}</p>
            </div>
            {dadosLogin?.max_rewards > 1 ? (
              <Swiper
                // install Swiper modules
                centeredSlides={true}
                slidesPerView="auto"
                grabCursor={true}
                pagination={{ clickable: true }}
                navigation
              >
                {loading ? (
                  <div className="loader">
                    <img src={imgLoader} alt="" />
                  </div>
                ) : (
                  produtos.map((data, index) => {
                    return (
                      <SwiperSlide className="premio" key={index}>
                        <img src={data.img} alt="" width={301} height={306} />
                        <h4>{data.name}</h4>

                        <Button
                          title={data.my_rewards_button_text}
                          onClick={() =>
                            linkResgate(data.key_one, data.key_two)
                          }
                        />
                      </SwiperSlide>
                    );
                  })
                )}
              </Swiper>
            ) : loading ? (
              <div className="loader">
                <img src={imgLoader} alt="" />
              </div>
            ) : (
              <div className="unicoResgate">
                {produtos.map((data, index) => {
                  return (
                    <div className="premio" key={index}>
                      <img src={data.img} alt="" width={301} height={306} />
                      <h4>{data.name}</h4>

                      <Button
                        title={data.my_rewards_button_text}
                        onClick={() => linkResgate(data.key_one, data.key_two)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <When expr={!configuracao?.disable_text_my_rewards}>
              <div className="topo-titulo">
                {dadosLogin?.max_rewards > 1 && (
                  <>
                    <div className="atencao">
                      <img src={img4} alt="" />
                      <p>
                        Você resgatou: {produtos.length}/
                        {dadosLogin?.max_rewards}
                      </p>
                    </div>
                    {produtos.length < dadosLogin?.max_rewards && (
                      <Button
                        title="Escolher mais um prêmio"
                        onClick={() =>
                          navigate("/" + configuracao?.key + "/loja")
                        }
                      />
                    )}
                  </>
                )}
                <div className="text">
                  <h1>{configFinal?.my_rewards_title}</h1>
                  <p>{configFinal?.my_rewards_subtitle}</p>
                </div>
                {configuracao?.use_video === 1 && (
                  <BoxVideo url={configuracao?.key} />
                )}
              </div>
            </When>

            {configuracao?.use_coupons === 1 ? (
              <div
                className="escolherMais"
                onClick={() => navigate("/" + configuracao?.key + "/loja")}
              >
                <img src={img6} alt="" />
                <p>Escolher MAIS uma experiência</p>
              </div>
            ) : null}

            <a ref={ref} href="" target="_blank" rel="noreferrer"></a>

            <ClickAjuda
              onClick={() => {
                navigate("/" + configuracao?.key + "/ajuda");
              }}
            >
              <img src={img3} alt="" />
              <span>
                Dúvidas? <span className="clique">Clique aqui</span> e fale com
                nosso parceiro YETZ
              </span>
            </ClickAjuda>
          </div>

          <Footer />

          <ModalAviso
            isOpen={active}
            mensagem={mensagem}
            handleClose={() => setActive(false)}
          />
          <MenuLateral
            isOpen={activeMenu}
            handleClose={() => setActiveMenu(false)}
            acao={configuracao?.name}
            url={configuracao?.key}
          />
        </Container>
      )}
    </>
  );
}
