import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.background || "#000d3c"};
  /* height: auto; */
  height: calc(100vh - 83px);
  position: relative;
  display: flex;
  flex-direction: column;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    .container-html {
      color: #ffffff;
      width: 276px;
      margin-left: 43px;
    }
    .container-text {
      width: 276px;
      margin-left: 43px;
      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 133%;
        display: flex;
        align-items: center;
        color: #ffffff;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 133%;
        display: flex;
        align-items: center;
        color: #ffffff;
        &:nth-of-type(1) {
          font-weight: 700;
        }
      }
    }
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 80px;
  }
  @media (max-width: 1211px) {
    flex-direction: column;
    padding: 0 10%;
    height: 100vh;
    .content {
      height: auto;
      flex-direction: column;
      .container-img {
        position: relative;
        top: 50px;
        align-self: flex-end;
        img {
          width: 193px;
        }
      }

      .container-text {
        width: 319px;
        margin-left: 0px;
        p {
          &:nth-of-type(2) {
            width: 276px;
          }
        }
      }
      .container-html {
        width: 319px;
        margin-left: 0px;
      }
    }
  }
`;
