import { Container, StyledModalGrupo } from "./styles";
import Button from "../../components/button";
import { useAcaoContext } from "../../contexts/acaoContext";
import imgPresent from "../../assets/img/present.svg";
import imgSetaEsq from "../../assets/img/seta-esq.svg";
import imgSetaDir from "../../assets/img/seta-dir.svg";
import imgClick from "../../assets/img/click.svg";
import imgFechar from "../../assets/img/fecha-preto.svg";

// import SwiperCore, { Navigation, A11y, Pagination } from "swiper";
import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { useState } from "react";
import { useEffect } from "react";

// SwiperCore.use([Navigation, A11y, Pagination]);

export function ModalGrupo(props) {
  const {
    isOpen,
    handleClose,
    prod,
    openResgate,
    handleSaibaMais,
    disableSaibaMais,
  } = props;
  const { setSaibaMaisLinkEstoque } = useAcaoContext();
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  function handleContainerClick(event) {
    const id = event.target.id;
    if (id === "modal-backdrop") {
      handleClose();
    }
  }

  useEffect(() => {
    if (prod?.products.length) {
      setSaibaMaisLinkEstoque(prod?.products[0].info.product_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prod?.products]);

  return (
    <Container
      id="modal-backdrop"
      data-show={isOpen}
      onClick={handleContainerClick}
    >
      <StyledModalGrupo className="content group">
        <div className="modal-header">
          <h5 className="modal-title">
            <img src={imgPresent} alt="" /> Defina seu prêmio
          </h5>
        </div>

        <div className="container-swiper">
          <Swiper
            // install Swiper modules
            modules={[Navigation, A11y, Pagination]}
            pagination={{ clickable: true }}
            onSlideChange={(swiper) => {
              setSaibaMaisLinkEstoque(
                prod?.products[swiper.realIndex].info.product_id
              );
            }}
            navigation={{
              nextEl,
              prevEl,
            }}
            breakpoints={{
              480: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
          >
            {prod?.products?.map((product) => {
              return (
                <SwiperSlide className="premio" key={product.id}>
                  <div className="container-img">
                    <img src={product?.info?.img} alt="" />
                  </div>

                  <h4>{product?.info?.name}</h4>

                  <Button
                    title="Escolher Prêmio"
                    onClick={() => {
                      openResgate(product.info);
                      handleClose();
                    }}
                  />
                  {disableSaibaMais !== 1 && (
                    <button
                      className="saiba-mais"
                      onClick={() => {
                        handleSaibaMais();
                      }}
                    >
                      {" "}
                      <img src={imgClick} alt="" /> Saiba mais
                    </button>
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="container-btns">
            <div ref={(node) => setPrevEl(node)} className="seta-esq">
              <img src={imgSetaEsq} alt="" />
            </div>
            <div ref={(node) => setNextEl(node)} className="seta-dir">
              <img src={imgSetaDir} alt="" />
            </div>
          </div>
        </div>

        <button className="close-modal" onClick={() => handleClose()}>
          <img src={imgFechar} alt="" />
        </button>
      </StyledModalGrupo>
    </Container>
  );
}
