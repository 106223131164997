import { useAcaoContext } from "../../contexts/acaoContext";
import { TailSpin } from "react-loader-spinner";
import { Container } from "./styles";

export default function Button(props) {
  const {
    title = "Acessar",
    type = "button",
    onClick = () => {},
    bgc = "",
    tipo = "",
    loading = false,
    disabled = false,
  } = props;

  const { configuracao } = useAcaoContext();

  return (
    <Container
      type={type}
      onClick={() => onClick()}
      bgc={bgc}
      className={tipo}
      disabled={disabled}
      button_color={configuracao?.button_color}
    >
      {loading ? (
        <TailSpin
          height="20"
          width="20"
          color="#fff"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      ) : (
        title
      )}
    </Container>
  );
}
