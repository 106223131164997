import { createContext, useContext, useState } from "react";
import Cookies from "universal-cookie";

import { api } from "../services/api";

const AcaoContext = createContext({ produtos: [] });

export const AcaoContextProvider = ({ children }) => {
  const cookies = new Cookies();
  const [mobile, setMobile] = useState("");
  const [timer, setTimer] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  const [configuracao, setConfiguracao] = useState(undefined);
  const [loadingConfiguracao, setLoadingConfiguracao] = useState(false);
  const [saibaMaisLinkEstoque, setSaibaMaisLinkEstoque] = useState("");
  const [link, setLink] = useState("");
  const [configLoja, setConfigLoja] = useState();

  const [produtos, setProdutos] = useState([]);

  const [productsGroup, setProductsGroup] = useState([]);

  async function fetchConfiguracao({
    chave,
    origin_benefit_click_id,
    onSuccess = () => undefined,
    onError = () => undefined,
  }) {
    try {
      setLoadingConfiguracao(true);

   
      if (!origin_benefit_click_id) {
        origin_benefit_click_id = cookies.get("yp_paramProduto");

        if (!origin_benefit_click_id) {
          origin_benefit_click_id = localStorage.getItem("yp_paramProduto");
        }
        
      }

      const data = await api.post(`/find`, {
        value: chave,
        origin_benefit_click_id: origin_benefit_click_id,
      });

      if (origin_benefit_click_id) {
        data.origin_benefit_click_id = origin_benefit_click_id;
      }
      
      setConfiguracao(data);
      onSuccess(data.key);
    } catch (errors) {
      setConfiguracao(undefined);
      if (errors && errors[0]) {
        onError(errors[0]);
      } else {
        onError("Algo deu errado!");
      }
    } finally {
      setLoadingConfiguracao(false);
    }
  }

  async function storeAction({
    id_acao,
    id_user,
    onSuccess = () => undefined,
    onError = () => undefined,
  }) {
    try {
      const data = await api.post(`/client/action/store`, {
        action_id: id_acao,
        user_id: id_user,
      });

      onSuccess(data);
    } catch (errors) {
      if (errors && errors[0]) {
        onError(errors[0]);
      } else {
        onError("Algo deu errado!");
      }
    }
  }

  return (
    <AcaoContext.Provider
      value={{
        mobile,
        setMobile,
        configuracao,
        loadingConfiguracao,
        fetchConfiguracao,
        storeAction,
        saibaMaisLinkEstoque,
        setSaibaMaisLinkEstoque,
        setProductsGroup,
        productsGroup,
        configLoja,
        setConfigLoja,
        produtos,
        setProdutos,
        timer,
        setTimer,
        userEmail,
        setUserEmail,
        link,
        setLink,
      }}
    >
      {children}
    </AcaoContext.Provider>
  );
};

export const useAcaoContext = () => useContext(AcaoContext);
