import { Container } from "./styles";
import { FormSms } from "./formSms";
import { FormWhats } from "./formWhats";
import { FormEmail } from "./formEmail";
import imgQuestion from "../../assets/img/questao.svg";
import imgLink from "../../assets/img/link.svg";
import imgSeta from "../../assets/img/seta-dir-preta.svg";
import imgCelular from "../../assets/img/celular.svg";
import { useRef } from "react";
import { useAcaoContext } from "../../contexts/acaoContext";

export function EscolhaTipoEnvio(props) {
  const { enviaCodigo, enviaCodigoEmail, url } = props;
  const ref = useRef(null);
  const { configuracao } = useAcaoContext();

  function handleChange(e) {
    const target = ref.current;
    const event = e.target;

    if (target) {
      const height = target.querySelector(".wraper-height").offsetHeight;
      if (target.classList.value.includes("active")) {
        target.classList.remove("active");
        event.parentElement.classList.remove("active");
        event.parentElement.parentElement.parentElement.classList.remove(
          "codigoVerifica"
        );
        target.style.height = "0px";
      } else {
        target.classList.add("active");
        event.parentElement.classList.add("active");
        event.parentElement.parentElement.parentElement.classList.add(
          "codigoVerifica"
        );
        target.style.height = `${height}px`;
      }
    }
  }

  function openArquivoTutorial() {
    if (url.includes("personnalite")) {
      window.open(
        "https://s3.us-east-1.amazonaws.com/img.yetzpromo.com.br/INFORMES/tutorial_desbloqueio_SMS_2023_itau_personnalite.pdf",
        "_blank"
      );
    } else if (url.includes("uniclass") || url.includes("uniclassapi")) {
      window.open(
        "https://s3.us-east-1.amazonaws.com/img.yetzpromo.com.br/INFORMES/tutorial_desbloqueio_SMS_2024_itau_uniclass.pdf",
        "_blank"
      );
    } else {
      window.open(
        "https://s3.us-east-1.amazonaws.com/img.yetzpromo.com.br/INFORMES/tutorial_desbloqueio_SMS_2021_promo.pdf",
        "_blank"
      );
    }
  }

  function openTutorialVerificacao() {
    if (url.includes("uniclass")) {
      window.open(
        "https://s3.us-east-1.amazonaws.com/img.yetzpromo.com.br/INFORMES/tutorial_desbloqueio_Whatsapp_2024_uniclass.pdf",
        "_blank"
      );
    } else {
      window.open(
        "https://s3.us-east-1.amazonaws.com/img.yetzpromo.com.br/INFORMES/tutorial_desbloqueio_Whatsapp_2024_person.pdf",
        "_blank"
      );
    }
  }

  return (
    <Container>
      <div className="container-btn" onClick={handleChange}>
        <button>
          <div className="container-img">
            <img src={imgQuestion} alt="" />
          </div>
          <div className="container-text">
            <h3>Clique aqui</h3>
            <p>(se não receber o Código pelo WhatsApp)</p>
          </div>
        </button>
      </div>

      <div className="container-envio" ref={ref}>
        <div className="wraper-height">
          {/* {url.includes("uniclass") || url.includes("personnalite") ? (
            <div className="container-info">
              <img src={imgCelular} alt="" />
              <div className="text">
                <p>
                  <strong>ATENÇÃO:</strong> Caso não tenha recebido o código,
                  confirme se o número do remetente não está bloqueado em seu
                  aparelho.
                </p>
                <p>
                  Para saber como realizar essa verificação,<br></br>
                  <span
                    className="clique-aqui"
                    onClick={() => openTutorialVerificacao()}
                  >
                    clique aqui.
                  </span>
                </p>
              </div>
            </div>
          ) : null} */}

          <div className="container-link" onClick={() => openArquivoTutorial()}>
            <div className="container-img">
              <img src={imgLink} alt="" />
            </div>
            <div className="container-text">
              <p>Entenda como liberar o bloqueio</p>
              <h3>DO SMS NO SEU CELULAR</h3>
            </div>
            <div className="container-img">
              <img src={imgSeta} alt="" />
            </div>
          </div>

          <div className="container-item">
            <FormSms onChange={enviaCodigo} />
          </div>

          <div className="container-divisor">
            <p>Ou</p>
          </div>

          <div className="container-item">
            <FormWhats onChange={enviaCodigo} />
          </div>

          {!configuracao.block_email_sending_on_login ? (
            <>
              <div className="container-divisor">
                <p>Ou</p>
              </div>

              <div className="container-item">
                <FormEmail onChange={enviaCodigoEmail} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Container>
  );
}
