import styled from "styled-components";
import { Container } from "../principal/styles";
import { Container as Btn } from "../../components/button/styles";
import { CodigoSms } from "../../components/modal/styles";

export const InvoiceContainer = styled.div`
  &.invoice-type {
    ${Container} {
      background: linear-gradient(196deg, #e86972 -5.68%, #febf3e 89.16%);
      ${Btn} {
        background-color: #e86972;
        height: 40px;
      }

      ${CodigoSms} {
        .content {
          .container-inputs {
            input {
              height: 60px;
            }
          }
          h3 {
            &.title {
              color: #e86972;
            }
          }
        }
      }

      .container {
        .form {
          span {
            color: #000;
            &.titulo {
              color: #fff;
            }
          }
        }
      }

      .container {
        .form {
          .form-login {
            input {
              height: 40px;
            }
          }
        }
      }
    }

    @media (max-width: 1280px) {
      .container {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
`;
