import { Formik, Form, ErrorMessage, Field } from "formik";
import { initialValues, validation } from "./config";
import { Container } from "./styles";
import Button from "../button";
import { useAcaoContext } from "../../contexts/acaoContext";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { useState } from "react";

//Pedro ão
//FC
//99992

export function RequiresParthner(props) {
  const { submit, form } = props;
  const { configuracao } = useAcaoContext();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(values) {
    const action_id = configuracao.id;
    try {
      setLoading(true);
      const response = await api.post("/client/createLoginParthner", {
        action_id,
        ...values,
      });

      form.setValue("login", response.login);
      await submit();
      setLoading(false);
    } catch (error) {
      toast.error("Algo de errado aconteceu, tente novamente mais tarde.");
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      <Form>
        <Container>
          <div className="container-input">
            <Field name="name" placeholder="DIGITE SEU NOME" />
            <ErrorMessage name="name" component="span" />
          </div>
          <div className="container-input">
            <Field name="parthner_store" placeholder="DIGITE NOME DA LOJA" />
            <ErrorMessage name="parthner_store" component="span" />
          </div>
          <div className="container-input">
            <Field name="parthner_f5" placeholder="DIGITE O F5" type="number" />
            <ErrorMessage name="parthner_f5" component="span" />
          </div>
        </Container>
        <Button title="Entrar" type="submit" loading={loading} />
      </Form>
    </Formik>
  );
}
