import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import Cookies from "universal-cookie";

import { EffectFade, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";

import { api } from "../../../services/api";

import Footer from "../../../components/footer";
import Button from "../../../components/button";
import CheckBox from "../../../components/checkbox";
import ModalAviso from "../../../components/modal/aviso";
import ModalAvisoHtml from "../../../components/modal/avisoHtml";
import ModalCodigoSms from "../../../components/modal/codigoVerificacao";
import { When } from "../../../components/when";
import { RenderLogo } from "../../../components/logos";

import { useAcaoContext } from "../../../contexts/acaoContext";

import { Container, ClickAjuda } from "../../principal/styles";
import { InvoiceContainer } from "../../principal/invoice";

import img1 from "../../../assets/img/mensagem.svg";
import imgMensagem from "../../../assets/img/mensagem-home-preto.svg";
import img2 from "../../../assets/img/carrossel-default.png";
import { RequiresParthner } from "../../../components/RequiresParthner";

export function Login() {
  const { configuracao } = useAcaoContext();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const { url } = useParams();

  const [searchParams] = useSearchParams();
  const paramProduto = searchParams.get("origin_benefit_click_id");

  const [openModalCodigo, setOpenModalCodigo] = useState(false);
  const [active, setActive] = useState(false);
  const [mensagem, setMensagem] = useState();
  const [imagens, setImagens] = useState([]);

  const [activeHtml, setActiveHtml] = useState(false);
  const [mensagemHtml, setMensagemHtml] = useState();

  const [verifyLogin, setVerifyLogin] = useState(false);
  const [validateValidator, setValidateValidator] = useState(false);

  const [telefone, setTelefone] = useState(false);

  const [termosPrimeiro, setTermosPrimeiro] = useState(false);
  const [termosSegundo, setTermosSegundo] = useState(false);

  const [loading, setLoading] = useState(false);
  const hasInvoiceType = configuracao?.invoice_type === 1;

  useEffect(() => {

    cookies.remove("yp_user", { path: "/" });
    cookies.remove("yp_dadosLogin", { path: "/" });
    cookies.remove("yp_paramProduto", { path: "/" });

    const timeCodeSending  = localStorage.getItem('timeCodeSending');
    
    if (timeCodeSending ) {
      const timeSending = new Date(timeCodeSending);
      const now = new Date();
      const timeLimit = new Date(timeSending.getTime() + 1 * 60 * 1000);
  
      if (now < timeLimit) {

        const storedData = localStorage.getItem('yp_user');
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setVerifyLogin(parsedData);
        }

        setOpenModalCodigo(true);
      }
    }
    // eslint-disable-next-line
  }, []);
  

  useEffect(() => {
    if (
      url === "uniclass" ||
      url === "personnalite" ||
      url === "personnalite3"
    ) {
      if (paramProduto) {
        //cookies.set("yp_paramProduto", paramProduto, { path: "/" });

        cookies.set("yp_paramProduto", paramProduto, {
          path: "/",
          sameSite: "None",
          secure: true,
        });
        localStorage.setItem("yp_paramProduto", paramProduto);
      } else {
        navigate("/ops", {
          state: {
            key: url,
          },
        });
      }
    }
    api
      .post(`/banners`, {
        action_id: configuracao?.id,
      })
      .then((data) => {
        setImagens(data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    if (configuracao?.use_popup === 1) {
      setMensagem(configuracao?.popup_text);
      setActive(true);
    }

    // eslint-disable-next-line
  }, [configuracao?.id, paramProduto]);

  const defaultValues = {
    login: "",
    validador: "",
    senha: "",
    confirme_senha: "",
  };

  const validationSchema = Yup.object().shape({
    login: Yup.string(),
  });

  const { formState, register, ...form } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  function saveUserData(data) {
    cookies.remove("yp_user", { path: "/", sameSite: "None", secure: true });
    cookies.set("yp_user", JSON.stringify(data), {
      path: "/",
      sameSite: "None",
      secure: true,
    });
    localStorage.setItem("yp_user", JSON.stringify(data));
  }

  const handleSubmit = form.handleSubmit((data) => {
    if (data.confirm_email) {
      const userAgent = navigator.userAgent;
      const cookies = document.cookie;
      const bodyData = JSON.stringify(data);
      const currentUrl = window.location.href;

      api
        .post(`client/verifyLogin/easy`, {
          login: data.login,
          data: new Date().toISOString(),
          userAgent: userAgent,
          url: currentUrl,
          cookies: cookies,
          bodyData: bodyData,
        })
        .then((response) => {
          console.log("Successful login", response);
        })
        .catch((error) => {
          console.error("Easy login error", error);
        });

      return;
    }

    if (!data.login) return;

    if (!verifyLogin) {
      if (
        configuracao?.login_type === "CPF" &&
        data.login.replace(/-|_/g, "").length !== 13
      ) {
        setMensagem("O CPF não foi preenchido corretamente");
        setActive(true);
        return;
      }
      setLoading(true);

      const sendLoginRequest = (token) => {
        const payload = {
          action_id: configuracao?.id,
          login: data.login,
        };

        if (token) {
          payload.recaptcha_token = token;
        }

        api
          .post(`/client/verifyLogin`, payload)
          .then((data) => {
            setLoading(false);
            setVerifyLogin(data);
            saveUserData(data);

            if (
              !data.use_validator &&
              !data.register_mobile &&
              !data.first_access
            ) {
              setOpenModalCodigo(true);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error && error[0]) {
              setMensagem(error[0]);
            } else {
              setMensagem(
                "Algo deu errado! Espere um pouco e recarregue a página."
              );
            }
            setActive(true);
          });
      };

      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: "submit" })
            .then((token) => {
              sendLoginRequest(token);
            });
        });
      } else {
        sendLoginRequest();
      }
    } else if (
      verifyLogin &&
      verifyLogin?.use_validator &&
      !validateValidator
    ) {
      setLoading(true);
      api
        .post(`/client/validateValidator`, {
          action_id: configuracao?.id,
          login: data.login,
          validator: data.validator,
        })
        .then((data) => {
          setLoading(false);
          setValidateValidator(data);
          saveUserData(data);
          if (!data.register_mobile) {
            setOpenModalCodigo(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error && error[0]) {
            setMensagem(error[0]);
          } else {
            setMensagem(
              "Algo deu errado! Espere um pouco e recarregue a página."
            );
          }
          setActive(true);
        });
    } else {
      if (
        verifyLogin &&
        (verifyLogin?.register_mobile ||
          validateValidator?.register_mobile ||
          verifyLogin?.first_access)
      ) {
        if (
          data.senha.replace(/-|_/g, "").length === 14 &&
          data.confirme_senha.replace(/-|_/g, "").length === 14
        ) {
          if (data.senha !== data.confirme_senha) {
            setMensagem("Telefones diferentes");
            setActive(true);
            return;
          } else {
            setTelefone(data.senha);

            if (configuracao?.disable_terms) {
              setLoading(true);
              api
                .post(`/client/validatewhitelist`, {
                  login: data.login,
                  mobile: data.senha,
                  confirm_mobile: data.confirme_senha,
                  user_id: String(verifyLogin?.user_id),
                })
                .then(() => {
                  setLoading(false);
                  setOpenModalCodigo(true);
                })
                .catch((error) => {
                  setLoading(false);
                  if (error && error[0]) {
                    setMensagem(error[0]);
                  } else {
                    if (error && error.message) {
                      setMensagem(error.message);
                    } else {
                      setMensagem(
                        "Algo deu errado! Espere um pouco e recarregue a página."
                      );
                    }
                  }
                  setActive(true);
                });
              return;
            }

            if (!!configuracao?.terms_text_1) {
              if (termosPrimeiro) {
                if (!!configuracao?.terms_text_2) {
                  if (termosSegundo) {
                    setOpenModalCodigo(true);
                  } else {
                    setMensagem("Os termos não foram aceitos.");
                    setActive(true);
                    return;
                  }
                } else {
                  setOpenModalCodigo(true);
                }
              } else {
                setMensagem("Os termos não foram aceitos.");
                setActive(true);
                return;
              }
            } else {
              if (!!configuracao?.terms_text_2) {
                if (termosSegundo) {
                  setOpenModalCodigo(true);
                } else {
                  setMensagem("Os termos não foram aceitos.");
                  setActive(true);
                  return;
                }
              }
            }
          }
        } else {
          setMensagem("O telefone não foi preenchido corretamente");
          setActive(true);
          return;
        }
      } else {
        setOpenModalCodigo(true);
      }
    }
  });

  const width = window.screen.width;

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=" +
      process.env.REACT_APP_RECAPTCHA_KEY;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll("#show-extra-modal");

    elements.forEach((el) => {
      el.addEventListener("click", (e) => {
        setMensagemHtml(configuracao?.policy_text);
        setActiveHtml(true);
      });
    });
  }, [configuracao, verifyLogin]);

  return (
    <InvoiceContainer className={`${hasInvoiceType ? "invoice-type" : ""}`}>
      <Container
        backgroundColor={configuracao?.background}
        enable_itau_layout={configuracao?.enable_itau_layout}
      >
        <div
          className={`container ${
            configuracao?.enable_itau_layout === 1 ? "itau" : ""
          }`}
        >
          {hasInvoiceType ? (
            width < 1024 ? (
              <img
                src={configuracao.logo}
                // eslint-disable-next-line react/style-prop-object
                className="logo-provisorio"
                alt=""
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <div className="container-logo-promo">
            <div className="container-swiper">
              <Swiper
                // install Swiper modules
                modules={[EffectFade, Autoplay]}
                effect="fade"
                fadeEffect={{
                  crossFade: true,
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                loop="true"
              >
                {!hasInvoiceType ? (
                  !!imagens?.length ? (
                    imagens.map((data, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <img src={data.banner} alt="" />
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <SwiperSlide>
                      <img src={img2} alt="" />
                    </SwiperSlide>
                  )
                ) : width > 480 ? (
                  <img src={configuracao.logo} alt="" />
                ) : (
                  <></>
                )}
              </Swiper>
            </div>
            {!hasInvoiceType ? (
              <div className="logos">
                <RenderLogo {...configuracao} />
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="form">
            <div className="logos">
              {!hasInvoiceType ? (
                <RenderLogo {...configuracao} isClickable={false} />
              ) : (
                <></>
              )}
            </div>
            <span className="parcerias">parcerias e benefícios</span>

            {configuracao?.enable_itau_layout === 1 ? (
              <>
                <span className="titulo">
                  Itaú Uniclass,{" "}
                  <span className="titulo-text">pra você chegar lá.</span>
                </span>
                <h3 className="subtitulo">
                  {configuracao?.login_subtitle ?? null}
                </h3>
              </>
            ) : (
              <>
                <span className="titulo">
                  {configuracao?.login_title ??
                    "o lugar certo para resgatar prêmios"}
                </span>
                <h3 className="subtitulo">
                  {configuracao?.login_subtitle ?? "PENSADOS PARA VOCÊ!!"}
                </h3>
              </>
            )}

            <div className="form-login principal">
              <When expr={Boolean(!configuracao.requires_parthner_data)}>
                <form onSubmit={handleSubmit}>
                  {configuracao?.login_type === "CPF" ? (
                    <InputMask
                      {...register("login")}
                      placeholder={
                        configuracao?.login_label ?? "Digite seu login"
                      }
                      type="tel"
                      mask="999.999.999-99"
                      disabled={verifyLogin}
                    />
                  ) : (
                    <input
                      {...register("login")}
                      placeholder={
                        configuracao?.login_label ?? "Digite seu login"
                      }
                      type="text"
                    />
                  )}

                  {verifyLogin?.use_validator && (
                    <InputMask
                      {...register("validator")}
                      placeholder={
                        verifyLogin?.validator_info.label ?? "Digite"
                      }
                      type="tel"
                      mask="99/99/9999"
                    />
                  )}

                  {(verifyLogin?.use_validator &&
                    validateValidator?.register_mobile) ||
                  (verifyLogin?.register_mobile &&
                    !verifyLogin?.use_validator) ||
                  verifyLogin?.first_access ? (
                    <>
                      <InputMask
                        {...register("senha")}
                        placeholder="Digite seu celular"
                        type="tel"
                        mask="(99) 99999-9999"
                      />
                      <InputMask
                        {...register("confirme_senha")}
                        placeholder="Confirme seu celular"
                        type="tel"
                        mask="(99) 99999-9999"
                      />

                      <When expr={!configuracao?.disable_terms}>
                        {!!configuracao?.terms_text_1 && (
                          <div className="box-checkbox">
                            <CheckBox
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setTermosPrimeiro(true);
                                } else {
                                  setTermosPrimeiro(false);
                                }
                              }}
                            />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: configuracao?.terms_text_1,
                              }}
                            ></span>
                          </div>
                        )}
                        {!!configuracao?.terms_text_2 && (
                          <div className="box-checkbox">
                            <CheckBox
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setTermosSegundo(true);
                                } else {
                                  setTermosSegundo(false);
                                }
                              }}
                            />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: configuracao?.terms_text_2,
                              }}
                            ></span>
                          </div>
                        )}
                      </When>
                    </>
                  ) : null}

                  <input
                    type="text"
                    {...register("confirm_email")}
                    style={{ display: "none" }}
                    tabIndex="-1"
                  />

                  <Button title="Entrar" type="submit" loading={loading} />
                </form>
              </When>

              <When expr={Boolean(configuracao.requires_parthner_data)}>
                {(verifyLogin?.use_validator &&
                  validateValidator?.register_mobile) ||
                (verifyLogin?.register_mobile &&
                  !verifyLogin?.use_validator) ? (
                  <form onSubmit={handleSubmit}>
                    <InputMask
                      {...register("senha")}
                      placeholder="Digite seu celular"
                      type="tel"
                      mask="(99) 99999-9999"
                    />
                    <InputMask
                      {...register("confirme_senha")}
                      placeholder="Confirme seu celular"
                      type="tel"
                      mask="(99) 99999-9999"
                    />

                    <When expr={!configuracao?.disable_terms}>
                      {!!configuracao?.terms_text_1 && (
                        <div className="box-checkbox">
                          <CheckBox
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setTermosPrimeiro(true);
                              } else {
                                setTermosPrimeiro(false);
                              }
                            }}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: configuracao?.terms_text_1,
                            }}
                          ></span>
                          {/* <span>
                          Li, compreendi e concordo com as disposições contidas
                          nos
                          <strong>
                            {" "}
                            Termos e Condições de Uso desta plataforma.
                          </strong>
                        </span> */}
                        </div>
                      )}
                      {!!configuracao?.terms_text_2 && (
                        <div className="box-checkbox">
                          <CheckBox
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setTermosSegundo(true);
                              } else {
                                setTermosSegundo(false);
                              }
                            }}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: configuracao?.terms_text_2,
                            }}
                          ></span>
                          {/* <span>
                          Li, compreendi e concordo com as disposições contidas na
                          <strong>
                            {" "}
                            Política de Privacidade desta Plataforma.
                          </strong>
                        </span> */}
                        </div>
                      )}
                    </When>

                    <input
                      type="text"
                      {...register("confirm_email")}
                      style={{ display: "none" }}
                      tabIndex="-1"
                    />

                    <Button title="Entrar" type="submit" loading={loading} />
                  </form>
                ) : (
                  <RequiresParthner submit={handleSubmit} form={form} />
                )}
              </When>
            </div>
            <ClickAjuda
              onClick={() => {
                navigate("/" + configuracao?.key + "/ajuda");
              }}
            >
              {!hasInvoiceType ? (
                <img src={img1} alt="" />
              ) : (
                <img src={imgMensagem} alt="" />
              )}

              <span>
                Dúvidas? <span className="clique">Clique aqui</span> e fale com
                nosso parceiro YETZ
              </span>
            </ClickAjuda>
            <When expr={configuracao?.use_lgpd}>
              {configuracao?.lgpd_text !== null && (
                <p
                  className="mais-info"
                  dangerouslySetInnerHTML={{
                    __html: configuracao?.lgpd_text,
                  }}
                ></p>
              )}
            </When>
          </div>
        </div>
        <Footer />

        <ModalCodigoSms
          isOpen={openModalCodigo}
          dadosUser={validateValidator ? validateValidator : verifyLogin}
          handleClose={() => setOpenModalCodigo(false)}
          url={configuracao?.key}
          telefone={telefone}
          {...configuracao}
        />

        <ModalAviso
          isOpen={active}
          mensagem={mensagem}
          handleClose={() => setActive(false)}
        />

        <ModalAvisoHtml
          isOpen={activeHtml}
          mensagem={mensagemHtml}
          handleClose={() => setActiveHtml(false)}
        />
      </Container>
    </InvoiceContainer>
  );
}
