import { useAcaoContext } from "../../contexts/acaoContext";
import { ErrorMessage, Form, Formik, Field } from "formik";
import { validationWhats, valuesWhats } from "./config";
import Button from "../button";
import imgWhats from "../../assets/img/whats-login.svg";
import { useState } from "react";

export function FormWhats(props) {
  const { onChange } = props;
  const { mobile, timer } = useAcaoContext();
  const [loading, setLoading] = useState(false);
  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={valuesWhats}
      validationSchema={validationWhats}
      onSubmit={async () => {
        setLoading(true);
        await onChange("WHATSAPP");
        setLoading(false);
      }}
    >
      {(props) => {
        return (
          <Form>
            <img src={imgWhats} alt="" />

            <div className="container-input">
              <h3>Receber por WhatsApp</h3>
              <Field name="sms" type="tel" value={mobile} disabled={true} />
              <ErrorMessage name="text" data-testid="error" component="span" />
            </div>

            <Button
              title="Reenviar"
              type="submit"
              loading={loading}
              disabled={timer}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
