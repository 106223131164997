import styled from "styled-components";

export const Container = styled.div`
  .container-input {
    margin-bottom: 8px;
    input {
      text-align: center;
      padding-right: 20px;
    }
    &:last-child {
      margin-bottom: 16px;
    }

    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
`;
