import Button from "../button";

import { Container } from "./styles";

export default function ModalAvisoHtml({ isOpen, handleClose, mensagem, cor }) {
  return (
    <Container data-show={isOpen} cor={cor}>
      <div className="content aviso">
        <div className="titulo">
          <div className="mensagem">
            <p
              dangerouslySetInnerHTML={{
                __html: mensagem,
              }}
            ></p>
            {/* <h2>{mensagem}</h2> */}
          </div>
        </div>
        <Button
          tipo="roxo"
          title="OK, ENTENDI"
          onClick={() => handleClose(!isOpen)}
        />
      </div>
    </Container>
  );
}
