import * as Yup from "yup";

export const valuesSms = {
  sms: "",
};

export const validationSms = Yup.object().shape({
  sms: Yup.string(),
});

export const valuesWhats = {
  whatsapp: "",
};

export const validationWhats = Yup.object().shape({
  whatsapp: Yup.string(),
});

export const valuesEmail = {
  email: "",
};

export const validationEmail = Yup.object().shape({
  email: Yup.string(),
});
