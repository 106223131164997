import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid ${({ cor }) => cor || "#6f3e98"};
  box-sizing: border-box;
  z-index: 100;
  width: 100%;
  height: 82px;

  .container-footer {
    width: 1300px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    border: none;
    height: 82px;
    min-height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
    .imgs {
      display: flex;
      justify-content: space-evenly;
      border-left: 3px solid ${({ cor }) => cor || "#343434"};
      padding-left: 50px;
      margin-left: 50px;
    }
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: ${({ cor }) => cor || "#343434"};
    text-align: center;
  }
  @media (max-width: 1280px) {
    .container-footer {
      width: 100%;
    }
  }
  @media (max-width: 1211px) {
    .container-footer {
      grid-template-columns: 1fr !important;
      padding: 0 20px;
      .imgs {
        display: none !important;
      }
    }
  }
`;
