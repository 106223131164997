import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import { api } from "../../services/api";

import { useAcaoContext } from "../../contexts/acaoContext";

import Button from "../button";
import CountDown from "../count";
import ModalAviso from "../modal/aviso";
import ModalAguardeEmissao from "../modal/aguardeEmissao";

import { CodigoSms } from "./styles";

import img1 from "../../assets/img/erro-code.svg";
import { EscolhaTipoEnvio } from "../escolhaTipoEnvio";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function ModalCodigoVerificacao(props) {
  const { isOpen, dadosUser, url, telefone, handleClose } = props;
  const navigate = useNavigate();
  const cookies = new Cookies();
  const {
    storeAction,
    setMobile,
    timer,
    setTimer,
    setUserEmail,
    configuracao,
  } = useAcaoContext();

  const [openModalEmissao, setOpenModalEmissao] = useState(false);
  const [store, setStore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAcessar, setLoadingAcessar] = useState(false);
  const [resgateAutomatico, setResgateAutomatico] = useState();

  const [sendSms, setSendSms] = useState(false);

  const [envioEmail, setEnvioEmail] = useState(false);
  const [emailFormatado, setEmailFormatado] = useState(false);

  const [qtdEnvioSms, setQtdEnvioSms] = useState(0);

  const [active, setActive] = useState(false);
  const [mensagem, setMensagem] = useState();
  const hasInvoiceType = configuracao?.invoice_type === 1;

  const [errorMessage, setErrorMessage] = useState('');

  const { register, ...form } = useForm({
    defaultValues: {
      primeiro: "",
      segundo: "",
      terceiro: "",
      quarto: "",
      quinto: "",
      sexto: "",
    },
  });

  function handlePaste(event) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text');
    const filteredData = pasteData.replace(/\D/g, '');
    const inputs = event.target.form.querySelectorAll('input[type="tel"]');
    filteredData.split('').forEach((char, index) => {
      if (inputs[index]) {
        inputs[index].value = char;
        if (inputs[index + 1]) {
          inputs[index + 1].focus();
        }
      }
    });
  }

  async function enviaCodigo(tipo = "") {
    setLoading(true);
    setEnvioEmail(false);

    try {
      await api.post(`/client/sendSms`, {
        user_id: dadosUser.user_id,
        by: tipo !== "" ? tipo : "SMS",
        mobile: telefone ? telefone : dadosUser.user_mobile,
      });
      setQtdEnvioSms(qtdEnvioSms + 1);
      setSendSms(true);
      setTimer(true);
      setLoading(false);
      toast.success("Código enviado com sucesso!");
      const timeItWasSent = new Date().toISOString();
      localStorage.setItem('timeCodeSending', timeItWasSent);
      
    } catch (error) {
      // setMensagem(error);
      // setActive(true);
      setLoading(false);
      if (error && error[0]) {
        toast.error(error[0]);
      } else {
        toast.error("Erro ao enviar código!");
      }
    }
  }

  async function enviaCodigoEmail(email) {
    setLoading(true);
    setEnvioEmail(true);

    var emailFormatado = dadosUser.user_email
      ? dadosUser.user_email.substr(0, 3) +
      "XXXX@" +
      dadosUser.user_email.split("@")[1]
      : email.substr(0, 3) + "XXXX@" + email.split("@")[1];
    setEmailFormatado(emailFormatado);

    try {
      await api.post(`/client/sendSms`, {
        user_id: dadosUser.user_id,
        by: "EMAIL",
        email: dadosUser.user_email ? dadosUser.user_email : email,
      });
      setSendSms(true);
      setTimer(true);
      setLoading(false);
      toast.success("Código enviado com sucesso!");
    } catch (error) {
      // setMensagem(error);
      // setActive(true);
      setLoading(false);
      if (error && error[0]) {
        toast.error(error[0]);
      } else {
        toast.error("Erro ao enviar código!");
      }
    }
  }

  const handleSubmit = form.handleSubmit(
    ({ primeiro, segundo, terceiro, quarto, quinto, sexto }) => {
      if (primeiro && segundo && terceiro && quarto && quinto && sexto) {
        const code = [primeiro, segundo, terceiro, quarto, quinto, sexto].join(
          ""
        );

        setLoadingAcessar(true);
        api
          .post(`/client/login`, {
            user_id: dadosUser.user_id,
            token: code,
          })
          .then((data) => {
            //cookies.remove("yp_dadosLogin", { path: "/" });
            //cookies.set("yp_dadosLogin", JSON.stringify(data), { path: "/" });
            //localStorage.setItem("yp_dadosLogin", JSON.stringify(data));

            cookies.remove("yp_dadosLogin", { path: "/", sameSite: "None", secure: true });
            cookies.set("yp_dadosLogin", JSON.stringify(data), { path: "/", sameSite: "None", secure: true });
            localStorage.setItem("yp_dadosLogin", JSON.stringify(data));
            localStorage.removeItem("timeCodeSending");

            if (!hasInvoiceType) {
              setResgateAutomatico(data.should_auto_reward);
            }
            if (data.rewards >= data.max_rewards) {
              setLoadingAcessar(false);
              if (hasInvoiceType) {
                navigate("/" + url + "/dashboard");
                return;
              }
              navigate("/" + url + "/finalizacao");
            } else {
              if (props.use_video) {
                setLoadingAcessar(false);
                navigate("/" + url + "/video");
              } else {
                if (data.should_auto_reward) {
                  setLoadingAcessar(false);
                  storeAction({
                    id_acao: props?.id,
                    id_user: dadosUser?.user_id,
                    onSuccess: (data) => {
                      setStore(data);
                      handleClose(!isOpen);
                      setOpenModalEmissao(true);
                      setLoadingAcessar(false);
                    },
                    onError: (erro) => {
                      if (erro && typeof erro === "string") {
                        setMensagem(erro);
                      } else {
                        setMensagem(
                          "Algo deu errado! Espere um pouco e recarregue a página."
                        );
                      }
                      setActive(true);
                      setLoadingAcessar(false);
                    },
                  });
                } else {
                  setLoadingAcessar(false);
                  if (hasInvoiceType) {
                    navigate("/" + url + "/dashboard");
                  } else {
                    navigate("/" + url + "/loja");
                  }
                }
              }
            }
          })
          .catch((error) => {
            document.querySelector(".container-inputs").classList.add("error");
            const errorMsg = error.message || "Código Inválido.";
            const shouldShowOnModal = error.show_on_modal || false;
            console.log('shouldShowOnModal', shouldShowOnModal);

            if (shouldShowOnModal) {
              setActive(true);
              setMensagem(errorMsg);
              handleClose();
              setSendSms(false);
            } else {
              setErrorMessage(errorMsg);
            }

            setLoadingAcessar(false);
          });
      }
    }
  );

  function handleKeyUp(event) {
    event.preventDefault();

    const { key } = event;

    if (key === "Backspace") {
      if (event.target.previousElementSibling) {
        event.target.previousElementSibling.focus();
      }
    } else {
      if (event.target.value.length === event.target.maxLength) {
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.focus();
        }
      }
    }
  }

  useEffect(() => {
    setMobile(telefone ? telefone : dadosUser.user_mobile);
    setUserEmail(dadosUser.user_email);

    const horarioEnvio = localStorage.getItem('timeCodeSending');

    if (horarioEnvio) {
      const tempoEnvio = new Date(horarioEnvio);
      const agora = new Date();
      const tempoLimite = new Date(tempoEnvio.getTime() + 1 * 60 * 1000);

      if (agora < tempoLimite) {
        setSendSms(true);
        setTimer(true);
        setLoading(false);
      }
    }
  }, [
    dadosUser.user_mobile,
    setMobile,
    dadosUser.user_email,
    setUserEmail,
    telefone,
    setTimer,
  ]);

  return (
    <>
      <CodigoSms
        data-show={isOpen}
        onSubmit={handleSubmit}
        cor={props.background}
      >
        <div className="content">
          <h3 className="title">código de verificação</h3>
          <p>
            Para concluir seu acesso você precisa{" "}
            <strong>INSERIR O CÓDIGO DE VERIFICAÇÃO</strong>, que será enviado
            para o {envioEmail ? "email" : "celular"} informado.
          </p>

          <span className="campoCelular">
            {envioEmail
              ? emailFormatado
              : telefone
                ? "(XX) XXXXX-" + telefone.substr(-4)
                : dadosUser.user_mobile}
          </span>

          <form>
            {sendSms && (
              <div className="container-inputs" id="codigo-sms">
                <div className="container-erro">
                  <img src={img1} alt="" />
                  <h5>{errorMessage || 'Código Inválido'}</h5>
                </div>
                <input
                  {...register("primeiro")}
                  type="tel"
                  maxLength="1"
                  onKeyUp={handleKeyUp}
                  onPaste={handlePaste}
                />
                <input
                  {...register("segundo")}
                  type="tel"
                  maxLength="1"
                  onKeyUp={handleKeyUp}
                  onPaste={handlePaste}
                />
                <input
                  {...register("terceiro")}
                  type="tel"
                  maxLength="1"
                  onKeyUp={handleKeyUp}
                  onPaste={handlePaste}
                />
                <input
                  {...register("quarto")}
                  type="tel"
                  maxLength="1"
                  onKeyUp={handleKeyUp}
                  onPaste={handlePaste}
                />
                <input
                  {...register("quinto")}
                  type="tel"
                  maxLength="1"
                  onKeyUp={handleKeyUp}
                  onPaste={handlePaste}
                />
                <input
                  {...register("sexto")}
                  type="tel"
                  maxLength="1"
                  onKeyUp={handleKeyUp}
                  onPaste={handlePaste}
                />
              </div>
            )}

            {sendSms && timer && <CountDown setTimer={setTimer} />}
            {!sendSms ? (
              <div className="container-enviar">
                <Button
                  title="Enviar WhatsApp"
                  type="submit"
                  tipo="branco"
                  loading={loading}
                  onClick={async () => {
                    await enviaCodigo("WHATSAPP");
                  }}
                />
              </div>
            ) : (
              <div className="container-enviar">
                <Button
                  title="Acessar"
                  type="submit"
                  tipo="branco"
                  loading={loadingAcessar}
                />
              </div>
            )}
          </form>

          {sendSms && !timer && (
            <EscolhaTipoEnvio
              enviaCodigo={enviaCodigo}
              enviaCodigoEmail={enviaCodigoEmail}
              url={url}
            />
          )}
        </div>
      </CodigoSms>

      <ModalAviso
        isOpen={active}
        mensagem={mensagem}
        handleClose={() => setActive(false)}
      />

      <ModalAguardeEmissao
        isOpen={openModalEmissao}
        handleClose={() => setOpenModalEmissao(false)}
        cor={props?.background}
        prod={store?.products?.length && store?.products[0]}
        automatico={resgateAutomatico}
        url={url}
        user={dadosUser.user_id}
        texto={props?.confirm_choose_modal_text}
      />
    </>
  );
}
