import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { api } from "../../services/api";

import Button from "../button";
import ModalAviso from "../modal/aviso";

import { Container } from "./styles";

import img from "../../assets/img/loader-yetz-3.svg";
import img1 from "../../assets/img/fecha-preto.svg";

export default function ModalAguardeEmissao(props) {
  const {
    isOpen,
    handleClose,
    user,
    cor,
    prod,
    url,
    automatico,
    textoAguarde,
  } = props;
  const navigate = useNavigate();

  const [active, setActive] = useState(false);
  const [mensagem, setMensagem] = useState();

  const [loader, setLoader] = useState(false);

  async function confirmaResgate() {
    setLoader(true);
    try {
      const data = await api.post(`/client/action/reward/obtain`, {
        user_id: user,
        variation_id: prod?.variation_id,
      });
      await monitorarResgate(data.id_to_verify);
      setLoader(false);
    } catch (error) {
      handleClose(!active);
      if (error && error[0]) {
        setMensagem(error[0]);
      } else {
        setMensagem("Algo deu errado! Espere um pouco e recarregue a página.");
      }
      setLoader(false);
      setActive(true);
    }
  }

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function monitorarResgate(v) {
    try {
      const response = await api.post(`/client/action/reward/check`, {
        reward_id: v,
      });

      if (!response.done && !response.error) {
        await timeout(5000);
        await monitorarResgate(v);
        return;
      } else if (response.error) {
        if (response.message) {
          setMensagem(response.message);
        } else {
          setMensagem(
            "Algo deu errado! Espere um pouco e recarregue a página."
          );
        }
        setLoader(false);
        handleClose();
        setActive(true);
        return;
      }

      setLoader(false);
      navigate("/" + url + "/finalizacao");
    } catch (error) {
      console.log(error);
    }
  }

  function handleContainerClick(event) {
    const id = event.target.id;
    if (id === "modal-backdrop" && !loader) {
      handleClose();
    }
  }

  useEffect(() => {
    if (isOpen && automatico) {
      setLoader(true);
      confirmaResgate();
    }
    // eslint-disable-next-line
  }, [isOpen, automatico]);

  const texto_extra = prod?.extra_reward_modal_info;
  const format_texto_extra = texto_extra?.split("*");

  return (
    <>
      <Container
        id="modal-backdrop"
        data-show={isOpen}
        cor={cor}
        onClick={handleContainerClick}
      >
        <div className="content resgate">
          {!loader && (
            <button className="close-modal" onClick={() => handleClose(false)}>
              <img src={img1} alt="" width={20} height={20} />
            </button>
          )}
          {prod && <img src={prod.img_modal} alt="" />}
          {!loader && (
            <h3>
              {prod?.confirm_choose_modal_text ??
                "Aguarde, estamos processando seu pedido!"}{" "}
            </h3>
          )}
          {!loader && (
            <span className="textoExtra">
              <strong>{format_texto_extra && format_texto_extra[1]}</strong>
              {format_texto_extra && format_texto_extra[2]}
            </span>
          )}
          {!loader && prod?.confirm_choose_modal_body && (
            <p className="textBody">{prod?.confirm_choose_modal_body ?? ""}</p>
          )}

          {!loader ? (
            <>
              <p>Após a confirmação, essa ação não poderá ser alterada.</p>
              <Button
                title="Confirmar"
                tipo="branco"
                onClick={() => confirmaResgate()}
              />
              <p
                className="depois"
                onClick={() => {
                  handleClose(false);
                }}
              >
                Voltar
              </p>
            </>
          ) : (
            <>
              <h3>
                {textoAguarde
                  ? textoAguarde
                  : "Aguarde, estamos processando seu pedido!"}
              </h3>
              <p>{prod?.name}</p>
              <img className="loader" src={img} alt="" />
            </>
          )}
        </div>
      </Container>

      <ModalAviso
        isOpen={active}
        mensagem={mensagem}
        handleClose={() => setActive(false)}
      />
    </>
  );
}
