import { Principal } from "../pages/principal";

import { Acao } from "../pages/acao";
import { Login } from "../pages/acao/login";
import { Video } from "../pages/acao/video";
import { Loja } from "../pages/acao/loja";
import { Carteira } from "../pages/acao/carteira";
import { Finalizacao } from "../pages/acao/finalizacao";
import { PageDashboardQRCode } from "../pages/acao/DashboardQRCode";
import { PageQRCode } from "../pages/acao/QrCode";
import { PageBarCode } from "../pages/acao/BarCode";
import { PageKeyCode } from "../pages/acao/KeyCode";
import { PrecisaAjuda } from "../pages/acao/precisaAjuda";
import { PageGrupo } from "../pages/acao/grupo";
import { LinkVoucher } from "../pages/acao/Link";
import { Manutencao } from "../components/manutencao";
import { AcessoNegadoItau } from "../components/AcessoNegadoItau";

export const publicRoutes = [
  { path: "/", element: <Principal /> },
  { path: "/ops", element: <AcessoNegadoItau /> },
];

export const privateRoutes = [
  {
    path: "/:url",
    element: <Acao />,
    children: [
      { path: "", element: <Login /> },
      { path: "finalizacao", element: <Finalizacao /> },
      { path: "dashboard", element: <PageDashboardQRCode /> },
      { path: "dashboard/qrcode", element: <PageQRCode /> },
      { path: "dashboard/bar-code", element: <PageBarCode /> },
      { path: "dashboard/key-code", element: <PageKeyCode /> },
      { path: "carteira", element: <Carteira /> },
      { path: "loja", element: <Loja /> },
      { path: "video", element: <Video /> },
      { path: "ajuda", element: <PrecisaAjuda /> },
      { path: "grupo", element: <PageGrupo /> },
      { path: "link", element: <LinkVoucher /> },
    ],
  },
  // {
  //   path: "/teste",
  //   element: <PageDashboardQRCode />,
  // },
];

export const manutencaoRoutes = [
  {
    path: "/:url",
    element: <Acao />,
    children: [
      {
        path: "",
        element: <Manutencao />,
      },
    ],
  },
];
