import styled from "styled-components";
import { Container as Button } from "../../components/button/styles";
import imgsetaCima from "../../assets/img/seta-top.svg";

export const Container = styled.div`
  padding-top: 17px;
  width: 327px;
  .container-btn {
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%) rotateX(-190deg);

      background-color: #0e0045;
      -webkit-mask: url(${imgsetaCima}) no-repeat;
      width: 13px;
      height: 9px;
      z-index: 20;
      transition: all 0.5s;
    }
    button {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      width: 280px;
      margin: 0 auto;
      margin-bottom: 29px;
      cursor: pointer;
      .container-img {
        pointer-events: none;
        img {
          margin: 0px;
        }
      }

      .container-text {
        text-align: left;
        margin-left: 8px;
        pointer-events: none;
        h3 {
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 124.5%;
          color: #000d3c;
          margin: 0px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 124.5%;
          color: #000d3c;
          margin: 0px;
          white-space: nowrap;
        }
      }
    }
  }

  &.active {
    .container-btn {
      &:before {
        transform: translateY(-50%) rotateX(0deg);
        transition: all 0.5s;
      }
    }
  }

  .container-envio {
    overflow: hidden;
    height: 0px;
    transition: all 0.5s;
    &.active {
      transition: all 0.5s;
    }
    /* height: 0px; */
    .container-link {
      border-radius: 100px;
      background: #efefef;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      grid-gap: 14px;
      margin-bottom: 30px;
      cursor: pointer;
      .container-img {
        img {
          margin: 0;
        }
      }
      .container-text {
        h3 {
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 124.5%;
          color: #000d3c;
          margin: 0px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 124.5%;
          color: #000d3c;
          margin: 0px;
          white-space: nowrap;
        }
      }
    }
    .container-info {
      background-color: #f6f6f6;
      border-radius: 5px;
      display: flex;
      align-items: flex-start;
      grid-gap: 15px;
      padding: 20px;
      font-family: Lato;
      margin-bottom: 20px;
      img {
        margin: 0;
        margin-top: 10px;
      }
      .text {
        p {
          font-size: 12px;
          text-align: left;
          line-height: 18px;
          strong {
            color: #dc7b14;
          }
          .clique-aqui {
            text-decoration: underline;
            text-transform: uppercase;
            color: #000;
            display: inline;
            font-size: 12px;
            font-weight: 800;
            cursor: pointer;
          }
        }
      }
    }
  }

  .container-item {
    width: 100%;
    padding-top: 20px;
    form {
      display: grid;
      grid-template-columns: 31px 1fr 90px;
      align-items: center;
      grid-gap: 0px 5px;

      .container-input {
        position: relative;
        h3 {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          color: #000000;
          margin: 0px;
          position: absolute;
          top: -18px;
          text-transform: unset;
        }
      }

      input {
        &:disabled {
          background: rgb(227, 227, 227);
          cursor: not-allowed;
        }
      }

      img {
        margin-bottom: 0px !important;
      }

      input {
        outline: none;
      }

      ${Button} {
        width: 100% !important;
        margin-bottom: 0px !important;
      }
    }
  }

  .container-divisor {
    text-align: center;
    position: relative;
    margin: 21px 0px 15px 0px;
    &:after {
      content: "";
      display: block;
      width: calc(53% - 27px);
      height: 1px;
      background: #000000;
      position: absolute;
      top: 50%;
      right: 0px;
    }
    &:before {
      content: "";
      display: block;
      width: calc(53% - 27px);
      height: 1px;
      background: #000000;
      position: absolute;
      top: 50%;
      left: 0px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      text-align: center;
      color: #000000;
      margin: 0px;
    }
  }

  @media (max-width: 1211px) {
    margin: 0 auto;
    width: 100%;
    .container-envio {
      &.active {
        overflow: inherit;
      }
    }
    .container-item {
      button {
        margin-top: 0px;
      }
    }
  }
`;
