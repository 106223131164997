import Countdown from "react-countdown";

import img from "../../assets/img/timer.svg";

export default function CountDown(props) {
  const { setTimer } = props;
  return (
    <Countdown
      date={Date.now() + 60000}
      onComplete={() => {
        setTimer(false);
      }}
      renderer={(props) => {
        const { hours, minutes, seconds } = props;

        return (
          <div className="timer">
            <img src={img} alt="" />
            <p ml="5px">{hours <= 9 ? "0" + hours : hours}</p>:
            <p>{minutes <= 9 ? "0" + minutes : minutes}</p>:
            <p>{seconds <= 9 ? "0" + seconds : seconds}</p>
          </div>
        );
      }}
    />
  );
}
